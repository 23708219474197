<template>
  <section class="top-banner mb-1">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <Agile :options="myOptions" class="owl-carousel owl-theme prices-of-flight theme-slider ltr">
            <div class="slide" v-for="(image, index) in topBanner" :key="index">
                <div class="img-holder" v-if="!!image.caption">
                  <img :src="image.image" alt="" class="card-img-top img-fluid linkon"/>
                  <a :href="image.href">
                    <div class="caption">
                      {{ image.caption }}
                    </div>
                  </a>
                </div>
                <div class="img-holder" v-else>
                  <img :src="image.image" alt="" class="card-img-top img-fluid"/>
                </div>
            </div>

            <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
            <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
          </Agile>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'TopBanner',
  components: {
    Agile: () => import('@/components/atoms/agile/Agile'),
  },
  props: ['topBanner'],
  data() {
    return {
      slide: 0,
      slides: ['assets/img/middle_banner_01.jpg', 'assets/img/middle_banner_02.jpg', 'assets/img/middle_banner_03.jpg', 'assets/img/middle_banner_04.jpg'],
      myOptions: {
        autoplay: true,
        infinite: true,
        slidesToShow: 1,
        centerMode: true,
        autoplaySpeed: 4000,
        rtl: true,
        dots: false,
        speed: 2000,
        navButtons: true,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              autoplay: false,
              navButtons: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 4,
              autoplay: false,
              navButtons: true,
            },
          },
        ],
      },
      mainProps: {
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb',
        class: 'card-img-top img-fluid',
      },
    };
  },
};
</script>

<style scoped>
.agile__slide {
  padding: 10px;
}
.img-holder {
  position: relative;
  overflow: hidden;
}
.img-holder img{
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}
.img-holder .caption {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 10px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  background: white;
  text-align: center;
  width: fit-content;
  transition: 0.5s ease-out;
  cursor: pointer;
}
.img-holder:hover .caption {
  top: calc(50% - 20px);
}
.img-holder:hover img.linkon {
  filter: brightness(0.7);
}
.img-holder:hover .caption {
  box-shadow: #ddf1f9 0px 0px 50px;
}
@media (max-width: 768px) {
  .owl-carousel.prices-of-flight .img-holder {
    height: auto;
  }
}
</style>
<style>

.top-banner .agile__nav-button {
  top: calc(50% - 20px);
}
</style>
